import { DeviceStatus } from '../apps/fleet'

export function isDeviceMonitored(device: { status: DeviceStatus }) {
  // pending, disabled, outOfOrder devices are NOT monitored -> no alerts
  return device.status === 'active'
}

export function isStatusOOO(status: DeviceStatus) {
  return status === 'outOfOrder'
}

export function isDeviceRecording(
  status: DeviceStatus,
): status is 'active' | 'pending' {
  return status === 'active' || status === 'pending'
}

const deviceStatusDisplay: Record<DeviceStatus, string> = {
  spare: 'En réserve',
  active: 'Actif',
  pending: "À l'essai",
  disabled: 'Désactivé',
  outOfOrder: 'Hors service',
}

export function getDeviceStatusDisplay(status: DeviceStatus) {
  return deviceStatusDisplay[status]
}

export const DEVICE_STATUS = Object.keys(deviceStatusDisplay) as DeviceStatus[]

export const NO_ROOM_VALUE = ''
