import { RoomRange } from '../apps/fleet'
import { Room } from '../types/utils'
import { roomsWithinRange } from './zone'

// i18n - add native common room on the fly
export function isCommonRoom(room: string) {
  const roomLC = room.toLowerCase()
  return (
    roomLC.includes('salon') ||
    roomLC.includes('salle') ||
    roomLC.includes('tisanerie') ||
    roomLC.includes('espace') ||
    roomLC.includes('hall') ||
    roomLC.includes('couloir') ||
    roomLC.includes('patio') ||
    roomLC.includes('wc')
  )
}

export const domicileSeparator = ' - '

export function isDomicileRoom(room: string) {
  return room.includes(domicileSeparator)
}

export function roomDisplayName(room: string) {
  return isCommonRoom(room) || isDomicileRoom(room) ? room : `Ch.\u00A0${room}`
}

// 🌪️ i18n warning : update accordinly in shared/src/utils/room.i18n.ts 🌪️
export function roomsInRange(rooms: Room[], roomRange: RoomRange | undefined) {
  if (roomRange === undefined) return 'Toutes les chambres'

  const roomsInRange = roomsWithinRange(rooms, roomRange)

  if (roomsInRange.length === 0) return 'Aucune chambre'

  if (roomsInRange.length === 1) return `Chambre ${roomsInRange[0]}`

  return `${roomsInRange.length} chambres, de ${roomsInRange[0]} à ${roomsInRange[roomsInRange.length - 1]}`
}
