import React, { useMemo, useState } from 'react'
import { FacilityDevice } from 'shared/apps/live'
import { DatabaseSchema } from 'shared/apps/live/databaseSchema'
import { Centered } from 'shared/components/Centered'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import { Serial } from 'shared/types/utils'
import { Deferred } from 'shared/utils/web/deferred'
import { USER_CLOSED_DIALOG } from '../../components/Dialog'
import { Button } from '../../components/ui/Button'
import { set } from '../../firebaseMethods'
import { useMergedFirebase } from '../../hooks/useMergedFirebase'
import { RoomCommentDialog } from './RoomCommentDialog'

const RoomCommentsReal: React.FC<{
  devices: Record<Serial, FacilityDevice>
}> = ({ devices }) => {
  const [roomCommentDialogData, setRoomCommentDialogData] = useState<{
    room: string
    initialComment: string
    deferred: Deferred<string>
  } | null>(null)

  // Load all device comments
  const refPathsMap = useMemo(
    () =>
      Object.keys(devices).reduce<Record<string, string>>((acc, serial) => {
        acc[serial] = `comments/${serial}`
        return acc
      }, {}),
    [devices],
  )

  const { data: devicesComments, loading } =
    useMergedFirebase<MergedType<'comments/${string}', DatabaseSchema>>(
      refPathsMap,
    )

  const handleComment = async (serial: string, room: string) => {
    const deferred = new Deferred<string>()
    const initialComment = devicesComments[serial] ?? ''
    setRoomCommentDialogData({ room, initialComment, deferred })
    try {
      const comment = await deferred.promise
      set(`comments/${serial}`, comment)
    } catch (error) {
      if (error !== USER_CLOSED_DIALOG) {
        throw error
      }
    } finally {
      setRoomCommentDialogData(null)
    }
  }

  if (loading) {
    return <Centered>Chargement...</Centered>
  }

  return (
    <>
      {Object.entries(devices).map(([serial, { room }]) => {
        return (
          <div className="flex flex-row items-baseline gap-3" key={serial}>
            <b>{room}</b>
            <span className="flex-1">{devicesComments[serial] ?? ''}</span>
            <Button
              variant="secondary"
              onClick={() => handleComment(serial, room)}
            >
              Éditer
            </Button>
          </div>
        )
      })}
      {roomCommentDialogData && (
        <RoomCommentDialog {...roomCommentDialogData}></RoomCommentDialog>
      )}
    </>
  )
}

export const RoomComments: React.FC<{
  devices: Record<Serial, FacilityDevice>
}> = ({ devices }) => {
  const [showRoomComments, setShowRoomComments] = useState(false)

  return (
    <div className="flex flex-col gap-3">
      <Button onClick={() => setShowRoomComments(!showRoomComments)}>
        {showRoomComments
          ? 'Cacher les commentaires sur les chambres'
          : 'Afficher les commentaires sur les chambres'}
      </Button>

      {showRoomComments && <RoomCommentsReal devices={devices} />}
    </div>
  )
}
