import { techChannelId } from 'common/channels'
import { Conversation } from 'shared/apps/live/types'
import { cn } from 'shared/utils/web/cn'
import { LastReadTS } from './LastReadContext'

interface Props {
  conversation: Conversation
  lastReadTS: LastReadTS
  conversationId?: string
}

const TECH_BG_NOTIFICATION = 'bg-violet-600'

export const UnreadCount: React.FC<Props> = ({
  conversation,
  lastReadTS,
  conversationId,
}) => {
  const newBubbles = Object.values(conversation).filter((bubble) =>
    lastReadTS ? bubble.timestamp > lastReadTS : true,
  )

  const count = newBubbles.length

  if (count === 0) return null

  return (
    <div
      className={cn(
        'bg-background-notification flex h-6 w-6 items-center justify-center rounded-full text-sm',
        conversationId === techChannelId ? TECH_BG_NOTIFICATION : '',
      )}
    >
      {count}
    </div>
  )
}
