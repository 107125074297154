import React from 'react'
import { Contacts } from 'shared/apps/live'
import { UserDiscussions } from 'shared/apps/live/types'
import { FacilityId } from 'shared/types/utils'
import { useUser } from '../../components/UserProvider'
import { useFirebase } from '../../hooks/useFirebase'

type ContactsContext = {
  contacts: Contacts
  discussions: UserDiscussions
  loading: boolean
  error: Error | null
}

export const contactsContext = React.createContext<ContactsContext>({
  contacts: {},
  discussions: {},
  loading: true,
  error: null,
})

interface Props {
  facilityId: FacilityId
}

export const ContactsProvider: React.FC<React.PropsWithChildren<Props>> = ({
  facilityId,
  children,
}) => {
  const { uid } = useUser()

  const {
    data: contacts,
    loading: loadingContacts,
    error: errorContacts,
  } = useFirebase(`contacts/${facilityId}`)

  const {
    data: discussions,
    loading: loadingDiscussions,
    error: errorDiscussions,
  } = useFirebase(`discussions/${facilityId}/${uid}`)

  const contextValue = {
    contacts: contacts ?? {},
    discussions: discussions ?? {},
    loading: loadingContacts || loadingDiscussions,
    error: errorContacts || errorDiscussions,
  }

  return (
    <contactsContext.Provider value={contextValue}>
      {children}
    </contactsContext.Provider>
  )
}
