import { alertDisplayText } from 'common/alert'
import { LinkIcon } from 'lucide-react'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Alert } from 'shared/apps/live/alert'
import { DatabaseSchema } from 'shared/apps/live/databaseSchema'
import { Centered } from 'shared/components/Centered'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import { FacilityId } from 'shared/types/utils'
import { dateTimeFromISO, noonDate } from 'shared/utils/timeFormat'
import { siteUrl } from 'shared/utils/url'
import { cn } from 'shared/utils/web/cn'
import { AlertQuote } from '../../../components/conversation/Quote'
import { Button } from '../../../components/ui/Button'
import { Card, CardContent, CardFooter } from '../../../components/ui/Card'
import { ScrollArea } from '../../../components/ui/ScrollArea'
import { Skeleton } from '../../../components/ui/Skeleton'
import { useFirebase } from '../../../hooks/useFirebase'
import { useMergedFirebase } from '../../../hooks/useMergedFirebase'
import { AnswerCommentedAlertDialog } from './AnswerCommentedAlertDialog'
import { BOTTOM_BORDER_CLASSNAME } from './FacilityPanelLayout'

const getLast7Days = (): string[] => {
  const dates: string[] = []
  for (let i = 0; i < 7; i++) {
    const ts = DateTime.now().minus({ day: i }).toFormat('yyyy-MM-dd') // Soustrait i jours
    dates.push(ts)
  }
  return dates.reverse()
}

export const Panel: React.FC<React.PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => {
  return (
    <div className="bg-muted flex max-w-[500px] flex-col">
      <div
        className={cn(
          BOTTOM_BORDER_CLASSNAME,
          'bg-header text-header-foreground p-2',
        )}
      >
        {title}
      </div>
      <div className="p-2">{children}</div>
    </div>
  )
}

type AlertsListProps = {
  facilityId: FacilityId
}

export const AlertsList = ({ facilityId }: AlertsListProps) => {
  const [alerts, setAlerts] = useState<[string, Alert | null][]>([])
  const refCommentedAlertsPathsMap = useMemo(() => {
    const dates = getLast7Days()
    return dates.reduce<Record<string, string>>((acc, date) => {
      acc[date] = `commentedAlerts/${facilityId}/${date}`
      return acc
    }, {})
  }, [facilityId])

  const {
    data: commentedAlertsPath,
    loading: commentedAlertsPathLoading,
    error: commentedAlertsPathError,
  } = useMergedFirebase<
    MergedType<'commentedAlerts/${string}/${string}', DatabaseSchema>
  >(refCommentedAlertsPathsMap)

  const refPathsMapWithSerials = useMemo(() => {
    if (!commentedAlertsPath) return {}
    return Object.entries(commentedAlertsPath).reduce<
      Record<string, { path: string; serial: string }>
    >((acc, [date, serials]) => {
      if (!serials) return acc
      for (const [serial, alerts] of Object.entries(serials)) {
        for (const alertId of Object.keys(alerts)) {
          acc[alertId] = {
            path: `alerts/${date}/${serial}/${alertId}`,
            serial: serial,
          }
        }
      }
      return acc
    }, {})
  }, [commentedAlertsPath])

  const refPathsMap = useMemo(() => {
    return Object.fromEntries(
      Object.entries(refPathsMapWithSerials).map(([alertId, { path }]) => [
        alertId,
        path,
      ]),
    )
  }, [refPathsMapWithSerials])

  const { data, loading, error } =
    useMergedFirebase<
      MergedType<'alerts/${string}/${string}/${string}', DatabaseSchema>
    >(refPathsMap)

  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    listRef.current?.scrollIntoView(false)
  }, [alerts])

  useEffect(() => {
    setAlerts(Object.entries(data || {}))
  }, [data])

  if (loading || commentedAlertsPathLoading)
    return <Centered>Chargement...</Centered>

  if (error || commentedAlertsPathError) return <Centered>Erreur</Centered>

  if (alerts.length === 0)
    return (
      <Centered>Pas d'alertes commentées sur les 7 derniers jours</Centered>
    )

  return (
    <ScrollArea className="h-[calc(100vh-162px)]">
      <div className="flex flex-col items-center gap-2" ref={listRef}>
        {alerts.map(([alertId, alert]) => {
          if (!alert || !refPathsMapWithSerials[alertId]) return null
          return (
            <AlertCommentCard
              alert={alert}
              serial={refPathsMapWithSerials[alertId].serial}
              key={alertId}
            />
          )
        })}
      </div>
    </ScrollArea>
  )
}

type AlertCommentProps = {
  alert: Alert
  serial: string
}

const AlertCommentCard: React.FC<AlertCommentProps> = ({ alert, serial }) => {
  const { ownership, date } = alert

  const comment = ownership?.comment

  const {
    data: user,
    loading: userLoading,
    error: userError,
  } = useFirebase(`users/${ownership?.uid}`)

  const {
    data: device,
    loading: deviceLoading,
    error: deviceError,
  } = useFirebase(`devices/${serial}`)

  if (!comment) {
    return null
  }

  if (userLoading || deviceLoading) return <AlertCardSkeleton />

  if (userError || deviceError) return <Centered>Erreur</Centered>

  const link = `${siteUrl('listen')}/${serial}/${noonDate(dateTimeFromISO(date))}/${date}`
  const formattedAlert = alertDisplayText(alert)
  const formattedRelativeDate = DateTime.fromISO(date).toRelative()

  const details = {
    type: alert.type,
    date,
    email: user?.email ?? '',
    room: device?.room ?? '',
  }

  return (
    <Card className="w-full">
      <CardContent className="flex flex-col gap-3 pt-6">
        <header className="flex items-center justify-between">
          <h3 className="text-lg font-medium">{formattedAlert}</h3>
          <time className="text-muted-foreground text-sm">
            {formattedRelativeDate}
          </time>
        </header>

        <AlertQuote quote={{ ...comment, details }} details={details} />
      </CardContent>
      <CardFooter className="flex justify-between">
        <AnswerCommentedAlertDialog comment={comment} details={details} />
        <Button variant="secondary" asChild>
          <a href={link} target="_blank">
            <LinkIcon className="mr-2 size-4" />
            Listen
          </a>
        </Button>
      </CardFooter>
    </Card>
  )
}

const AlertCardSkeleton = () => {
  return (
    <Card className="w-full">
      <CardContent className="flex flex-col gap-3 pt-6">
        <header className="flex items-center justify-between">
          <Skeleton className="h-4 w-1/3" />
          <Skeleton className="h-4 w-1/4" />
        </header>
        <div className="space-y-2">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-4/6" />
        </div>
      </CardContent>
      <CardFooter className="flex justify-between">
        <Skeleton className="h-10 w-[125px]" />
        <Skeleton className="h-10 w-[85px]" />
      </CardFooter>
    </Card>
  )
}
