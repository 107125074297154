import { Trans } from '@lingui/react/macro'
import { UPLOAD_IN_PROGRESS } from 'common/channels'
import {
  AlertCircle,
  CircleCheck,
  Image,
  LoaderCircle,
  Play,
} from 'lucide-react'
import { DateTime } from 'luxon'
import React from 'react'
import { ActionType } from 'shared/apps/live/databaseSchema'
import {
  Bubble as BubbleType,
  isAudioBubble,
  isNotificationBubble,
  isTextBubble,
  NotificationBubble as NotificationBubbleType,
} from 'shared/apps/live/types'
import { formatTime } from 'shared/utils/timeFormat'
import { cn } from 'shared/utils/web/cn'
import { AudioPlayer } from '../AudioPlayer'
import ExpandableImage from '../ExpandableImage'
import { Button } from '../ui/Button'
import { AlertQuote } from './Quote'

interface Props {
  bubble: BubbleType
  isUserBubble: boolean
  author: string | undefined
  zone: string | undefined
}

export const Bubble: React.FC<Props> = ({
  bubble,
  author,
  zone,
  isUserBubble,
}) => {
  if (isNotificationBubble(bubble)) {
    return <NotificationBubble bubble={bubble} />
  }

  const isUploadingAudioBubble =
    isAudioBubble(bubble) && bubble.sound.url === UPLOAD_IN_PROGRESS

  const isUploadingImageBubble = bubble.image?.url === UPLOAD_IN_PROGRESS

  return (
    <div
      className={cn(
        'flex flex-col',
        isUserBubble ? 'items-end' : 'items-start',
      )}
    >
      <div
        className={cn(
          'mb-1 flex items-center gap-2',
          isUserBubble ? 'justify-end' : 'justify-start',
        )}
      >
        {author && (
          <span className="text-sm font-bold">
            {zone && <>{zone} - </>}
            {author}
          </span>
        )}
        <time className="text-sm">{formatDate(bubble.timestamp)}</time>
      </div>
      <div
        className={cn(
          'flex flex-col gap-1 rounded-lg p-3 shadow-sm',
          isUserBubble
            ? 'bg-self text-self-foreground rounded-tr-none'
            : 'bg-oso-secondary rounded-tl-none text-white',
          isAudioBubble(bubble) ? 'w-[80vw] max-w-96' : 'w-fit max-w-[80vw]',
        )}
      >
        {bubble.quote && (
          <AlertQuote quote={bubble.quote} details={bubble.quote.details} />
        )}
        {isTextBubble(bubble) && (
          <p className="whitespace-pre-wrap text-pretty">{bubble.text}</p>
        )}

        {isAudioBubble(bubble) &&
          (isUploadingAudioBubble ? (
            <div className="bg-secondary flex h-10 items-center justify-between rounded-md shadow-sm">
              <Button size="icon" variant="secondary" type="button" disabled>
                <Play className="size-4" />
              </Button>
              <div className="flex grow justify-center">
                <LoaderCircle className="text-secondary-foreground size-6 animate-spin" />
              </div>
            </div>
          ) : (
            <AudioPlayer url={bubble.sound.url} />
          ))}
        {bubble.image &&
          (isUploadingImageBubble ? (
            <div className="relative mt-2 flex size-24 items-center justify-center border">
              <Image className="absolute inset-0 h-full w-full object-cover text-gray-400" />
              <LoaderCircle className="relative size-8 animate-spin" />
            </div>
          ) : (
            <ExpandableImage
              src={bubble.image.url}
              alt="Bubble Image"
              className="mt-2 h-auto max-h-64 w-auto max-w-full object-contain"
            />
          ))}
      </div>
    </div>
  )
}

const NotificationBubble = ({ bubble }: { bubble: NotificationBubbleType }) => {
  const { notification } = bubble

  return (
    <div className="flex w-full flex-col items-center">
      <time className="w-full text-sm">{formatDate(bubble.timestamp)}</time>
      <div
        className={cn(
          'flex w-full items-center justify-start gap-2 rounded-xl border p-2 md:w-fit md:justify-center',
        )}
      >
        {notification.type === ActionType.REPORT_DEVICE && (
          <>
            <AlertCircle className="size-8 shrink-0 text-orange-500" />
            <span className="text-lg font-bold">
              <Trans>
                Un problème a été signalé sur le boitier #{notification.id}
              </Trans>
            </span>
          </>
        )}
        {notification.type === ActionType.OFFLINE_DEVICE && (
          <>
            <AlertCircle className="size-8 shrink-0 text-orange-500" />
            <span className="text-lg font-bold">
              <Trans>
                Le boitier #{notification.id} a été signalé hors-ligne
              </Trans>
            </span>
          </>
        )}
        {notification.type === ActionType.ONLINE_DEVICE && (
          <>
            <CircleCheck className="size-8 shrink-0 text-green-500" />
            <span className="text-lg font-bold">
              <Trans>Le boitier #{notification.id} est en ligne</Trans>
            </span>
          </>
        )}
      </div>
    </div>
  )
}

function formatDate(timestamp: number) {
  const date = DateTime.fromMillis(timestamp)
  const today = DateTime.now()

  if (date.hasSame(today, 'day')) {
    return formatTime(date)
  }

  return date.toLocaleString({
    weekday: 'long',
    day: '2-digit',
    month: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  })
}
