import {
  AlertEvaluation,
  AlertListener,
  AlertValidation,
  LtsLevel,
} from 'shared/apps/live/alert'
import { automaticUid } from 'shared/apps/live/types'
import { serverTimestamp } from 'shared/firebase/serverValue'

export function alertValidation(ltsLevel: LtsLevel) {
  const value: AlertValidation = {
    uid: automaticUid,
    timeStamp: serverTimestamp(),
    isValidated: true,
    ltsLevel,
  }

  return value
}

export function alertEvaluation(uid: string, isConfirmed: boolean) {
  const value: AlertEvaluation = {
    uid,
    isConfirmed,
    timeStamp: serverTimestamp(),
  }

  return value
}

export function alertListener(playProgress: number) {
  const value: AlertListener = {
    playProgress,
  }

  return value
}
