import { FirebaseKey, Serial, UserId } from '../../types/utils'

export type LtsLevel = 'error' | 'mute' | 'notification' | 'comfort' | 'urgent'

export type AlertType =
  | 'AS_CALL'
  | 'BEEP'
  | 'BREATHING'
  | 'BREATHING_ASTHMA'
  | 'BREATHING_EDEMA'
  | 'BREATHING_JANUS'
  | 'BREATHLESSNESS'
  | 'BOOM'
  | 'CANCEL_ROOM_EXIT'
  | 'CLICKER'
  | 'CRY'
  | 'DRUM'
  | 'FALL_JANUS'
  | 'PUKE'
  | 'PUKE_JANUS'
  | 'ROOM_EXIT'
  | 'SPEECH'
  | 'TEETH_GRINDING'
  | 'WAKEUP'
  | 'WHINE'
  | 'YELL'

// Alert types in test, hidden everywhere (live, reporting...)
export const testAlertTypes: AlertType[] = [
  'BREATHING_JANUS',
  'FALL_JANUS',
  'PUKE_JANUS',
]

// Alerts not needed to be shown for highlight in live
// Warning: None of these alerts are highlighted, even in facilities where they are deployed.
// TODO: Fix this issue.
export const notHighlightedAlertTypes: AlertType[] = [
  ...testAlertTypes, // Include all testAlertTypes
  'CANCEL_ROOM_EXIT',
  'CLICKER',
  'ROOM_EXIT',
  'WAKEUP',
  'TEETH_GRINDING',
]

// Optional category used in Zone definitions
export type Category = 'emergency' | 'comfort'

// 🌪️ i18n warning : update accordinly in shared/src/types/alert.i18n.ts 🌪️
export const categoryString: Record<Category, string> = {
  emergency: 'Urgence',
  comfort: 'Confort',
}

export const categoryColor: Record<Category, string> = {
  emergency: 'bg-fuchsia-700',
  comfort: 'bg-teal-700',
}

// Also update notificationCategory() in functions/notifications
// when modifying here
export function alertCategory(type: AlertType, ltsLevel?: LtsLevel): Category {
  if (type === 'WAKEUP') {
    return 'comfort'
  } else if (ltsLevel === 'comfort') {
    return 'comfort'
  } else {
    return 'emergency'
  }
}

export function enableAudioPlayer(type: AlertType) {
  // a CANCEL_ROOM_EXIT should never be validated
  return type !== 'ROOM_EXIT' && type !== 'CLICKER' && type !== 'WAKEUP'
}

type TextComment = { comment: string }
type AudioComment = { audioCommentURL: string; audioCommentPath: string }
export type AlertComment = TextComment | AudioComment

export function isTextComment(comment: AlertComment): comment is TextComment {
  return (comment as TextComment).comment !== undefined
}

export function isAudioComment(comment: AlertComment): comment is AudioComment {
  return (comment as AudioComment).audioCommentURL !== undefined
}

export interface AlertListener {
  playProgress: number
  firstPlayTimestamp?: number
}

export interface Ownership {
  uid: UserId
  startTS: number
  comment?: AlertComment
  playProgress?: number
  // when not set, alert is currently owned and treated by user uid
  endTS?: number
}

export interface AlertEvaluation {
  uid: string
  timeStamp: number
  isConfirmed: boolean | 'UNKNOWN'
}

export interface AlertValidation {
  uid: string
  timeStamp: number
  isValidated: boolean
  ltsLevel: LtsLevel
}

export type AlertListeners = Record<UserId, AlertListener>

interface AlertBase {
  date: string
  type: AlertType
  validation?: AlertValidation
  soundURL: string
  comment?: string
  ownership?: Ownership
  listeners?: AlertListeners // from AS
  evaluation?: AlertEvaluation // from us
  phoneCalls?: Record<FirebaseKey, string>
  auditUrl?: string
  auditUrlExpiration?: number
}

interface BeepAlert extends AlertBase {
  type: 'BEEP'
  beepType: BeepType[]
}

export function isBeepAlert(alert: Alert): alert is BeepAlert {
  return alert.type === 'BEEP'
}

interface RoomExitAlert extends AlertBase {
  type: 'ROOM_EXIT'
  delay: number
}

export function isRoomExitAlert(alert: Alert): alert is RoomExitAlert {
  return alert.type === 'ROOM_EXIT'
}

interface VocalAlert extends AlertBase {
  type: 'SPEECH' | 'AS_CALL'
  rawKeywords: string[] // Non translated keywords
  keywordClasses: string[] // Language agnostic internal class, 1-1 mapping with rawKeywords array
}

export function isVocalAlert(alert: Alert): alert is VocalAlert {
  return alert.type === 'SPEECH' || alert.type === 'AS_CALL'
}

export type Alert = AlertBase | BeepAlert | RoomExitAlert | VocalAlert

export type Alerts = Record<FirebaseKey, Alert>
export type SerialAlerts = Record<Serial, Alerts>

// 🌪️ i18n warning : update accordinly in shared/src/types/alert.i18n.ts 🌪️
export const alertTypeString: Record<AlertType, string> = {
  AS_CALL: 'Appel',
  BEEP: 'Bip machine',
  BOOM: 'Choc inquiétant',
  BREATHING: 'Gêne respiratoire',
  BREATHING_ASTHMA: 'Respiration sifflante',
  BREATHING_EDEMA: 'Détresse respiratoire',
  BREATHING_JANUS: 'Problème respiratoire (JANUS)',
  BREATHLESSNESS: 'Essoufflement prolongé',
  CANCEL_ROOM_EXIT: 'Non sortie de chambre',
  CLICKER: 'Appel bouton',
  CRY: 'Pleurs',
  DRUM: 'Tambourinage',
  FALL_JANUS: 'Choc inquiétant (JANUS)',
  PUKE: 'Vomissement',
  PUKE_JANUS: 'Vomissement (JANUS)',
  ROOM_EXIT: 'Sortie de chambre',
  SPEECH: 'Appel',
  TEETH_GRINDING: 'Grincement de dents',
  WAKEUP: 'Réveil',
  WHINE: 'Gémissement',
  YELL: 'Cri',
}

export const alertTypeColor: Record<AlertType, string> = {
  AS_CALL: 'bg-green-600',
  BEEP: 'bg-gray-600',
  BOOM: 'bg-red-600',
  BREATHING: 'bg-teal-400',
  BREATHING_ASTHMA: 'bg-pink-800',
  BREATHING_EDEMA: 'bg-pink-500',
  BREATHING_JANUS: 'bg-teal-600',
  BREATHLESSNESS: 'bg-cyan-600',
  CANCEL_ROOM_EXIT: 'bg-purple-600',
  CLICKER: 'bg-green-700',
  CRY: 'bg-yellow-400',
  DRUM: 'bg-blue-600',
  FALL_JANUS: 'bg-red-400',
  PUKE: 'bg-lime-600',
  PUKE_JANUS: 'bg-lime-400',
  ROOM_EXIT: 'bg-purple-700',
  SPEECH: 'bg-teal-600',
  TEETH_GRINDING: 'bg-green-200',
  WAKEUP: 'bg-indigo-600',
  WHINE: 'bg-orange-400',
  YELL: 'bg-rose-300',
} as const

export const alertTypeColorHex: Record<AlertType, string> = {
  AS_CALL: '#2f855a', // green-600
  BEEP: '#4a5568', // gray-600
  BOOM: '#e53e3e', // red-600
  BREATHING: '#4fd1c5', // teal-400
  BREATHING_ASTHMA: '#9d174d', // bg-pink-800
  BREATHING_EDEMA: '#d53f8c', // pink-500
  BREATHING_JANUS: '#4fd1c5',
  BREATHLESSNESS: '#00b5d8', // cyan-600
  CANCEL_ROOM_EXIT: '#6b46c1', // purple-600
  CLICKER: '#276749', // green-700
  CRY: '#ecc94b', // yellow-400
  DRUM: '#2b6cb0', // blue-600
  FALL_JANUS: '#e53e3e',
  PUKE: '#84cc16', // lime-600
  PUKE_JANUS: '#84cc16',
  ROOM_EXIT: '#553c9a', // purple-700
  SPEECH: '#319795', // teal-600
  TEETH_GRINDING: '#BBF7D0', // green-200
  WAKEUP: '#5a67d8', // indigo-600
  WHINE: '#fb923c', // orange-400
  YELL: '#f43f5e', // rose-500
} as const

export type BeepType =
  | 'pousse_seringue_vide'
  | 'pousse_seringue_presque_vide'
  | 'pompe'
  | 'matelas'
  | 'oxygene'
  | 'dinamap'
  | 'girafe'
  | 'pompe_erreur_occlusion'
  | 'electrocardiogramme'
  | 'extracteur_o2'
  | 'detecteur_de_fumee'
  | 'detecteur_de_mouvement'
  | 'vni'
  | 'dextro'

// Définir BEEP_TYPE_MAPPING en utilisant le type BeepTypeMapping
export const BEEP_TYPE_MAPPING: Record<string, BeepType> = {
  dinamap: 'dinamap',
  nutrition: 'pompe',
  pousse_seringue_vide: 'pousse_seringue_vide',
  pousse_seringue_presque_vide: 'pousse_seringue_presque_vide',
  girafe: 'girafe',
  matelas_bohars_lyon: 'matelas',
  matelas_bohars_bip_court: 'matelas',
  oxygene: 'oxygene',
  applix_erreur_occlusion: 'pompe_erreur_occlusion',
  amika_erreur_occlusion: 'pompe_erreur_occlusion',
  sapphire: 'pompe',
  oxygene_invacare: 'oxygene',
  alarme_pompe_agilia: 'pompe',
  electrocardiogramme: 'electrocardiogramme',
  alimentation_enteral: 'pompe',
  alarme_pompe_bohars: 'pompe',
  nutri_pompe_st_george: 'pompe',
  extracteur_o2: 'extracteur_o2',
  detecteur_de_fumee_vincent: 'detecteur_de_fumee',
  detecteur_de_fumee_domicile_rennes: 'detecteur_de_fumee',
  detecteur_de_mouvement: 'detecteur_de_mouvement',
  vni: 'vni',
  pompe_perfusion_bohars: 'pompe',
  dextro: 'dextro',
}
