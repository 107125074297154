import { DateTime } from 'luxon'
import { RoomRange, Zone, Zones } from '../apps/fleet'
import { Category } from '../apps/live/alert'
import { FirebaseKey, Room } from '../types/utils'
import { isHoliday } from './timeFormat'
import { isWithinTimeRange } from './timeRange'
import { collator } from './web/collator'

export function roomIsInZone(room: string, zone: Zone) {
  const regions = Object.values(zone.regions ?? {})

  return regions.some(
    (region) =>
      region.roomRange === undefined ||
      isWithinRoomRange(room, region.roomRange),
  )
}

export function roomIsInZoneAtTime(
  room: string,
  dateTime: DateTime,
  zone: Zone,
) {
  const regions = Object.values(zone.regions ?? {})

  // case of zones without region (allow to display all rooms, ie for '_Technicien' zone at BEAUREPAIRE-AFIPH)
  if (regions.length === 0) return true

  return regions.some(
    (region) =>
      (region.roomRange === undefined ||
        isWithinRoomRange(room, region.roomRange)) &&
      (region.timeRange === undefined ||
        isWithinTimeRange(dateTime, region.timeRange)),
  )
}

export function isWithinRoomRange(room: string, roomRange: RoomRange) {
  return (
    collator.compare(room, roomRange.from) >= 0 &&
    collator.compare(room, roomRange.to) <= 0
  )
}

export const roomsWithinRange = (
  rooms: Room[],
  roomRange: RoomRange | undefined,
): Room[] => {
  if (!roomRange) return []
  return rooms.filter((room) => isWithinRoomRange(room, roomRange))
}

export function alertIsInZone(
  room: Room,
  dateTime: DateTime,
  category: Category,
  zone: Zone,
) {
  const regions = Object.values(zone.regions ?? {})

  return regions.some(
    (region) =>
      (region.roomRange === undefined ||
        isWithinRoomRange(room, region.roomRange)) &&
      (region.timeRange === undefined ||
        isWithinTimeRange(dateTime, region.timeRange)) &&
      (region.category === undefined || region.category === category),
  )
}

type SortableZoneEntry = [FirebaseKey, { order: number }]

export function zoneSorter(
  [_keyA, { order: orderA }]: SortableZoneEntry,
  [_keyB, { order: orderB }]: SortableZoneEntry,
) {
  return orderA - orderB
}

// renvoi les bornes d'une roomRange (si pas de roomRange "Toutes les chambres")
export const roomRangeString = (roomRange: RoomRange | undefined) => {
  return roomRange
    ? `Chambres ${roomRange.from} à ${roomRange.to}`
    : 'Toutes les chambres'
}

// get holiday Zone if today is public holiday or weekend
export const getRealZone = (zones: Zones, zone: Zone, date: DateTime) => {
  const holidayZoneId = zone.holidayZoneId

  if (holidayZoneId && isHoliday(date)) return zones[holidayZoneId]

  return zone
}
