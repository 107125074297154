import { Trans, useLingui } from '@lingui/react/macro'
import {
  ADMIN_USER_ID,
  getSenderId,
  osoChannelId,
  techChannelId,
} from 'common/channels'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ConversationId } from 'shared/apps/live/types'
import { Centered } from 'shared/components/Centered'
import { FacilityId, FirebaseKey } from 'shared/types/utils'
import { cn } from 'shared/utils/web/cn'
import { Bubble } from '../../components/conversation/Bubble'
import { useUser } from '../../components/UserProvider'
import { update } from '../../firebaseMethods'
import { useFirebase } from '../../hooks/useFirebase'
import { LastReadTS } from '../Communication/LastReadContext'
import { ConversationContext } from './Communication'
import { Composer } from './Composer'
import { translateChannelNames } from './Contacts'
import { contactsContext } from './contactsProvider'

interface Props {
  conversationContext: ConversationContext
  facilityId: FacilityId
  conversationId: ConversationId | undefined
  lastReadTS?: LastReadTS
  updateLastReadTS?: (ts: number) => void
}

export const Conversation: React.FC<Props> = ({
  conversationContext,
  facilityId,
  conversationId,
  lastReadTS,
  updateLastReadTS,
}) => {
  const user = useUser()
  const { uid } = user

  const { contacts } = useContext(contactsContext)
  const { t } = useLingui()
  const {
    data: conversation,
    loading,
    error,
  } = useFirebase(
    `conversations/${facilityId}/${conversationId ?? '_not_defined_'}`,
  )

  const [firstNewBubbleKey, setFirstNewBubbleKey] = useState<FirebaseKey>()
  const hasUpdatedLastRead = useRef(false)

  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    listRef?.current?.scrollTo(0, listRef?.current?.scrollHeight)
  }, [conversation])

  useEffect(() => {
    if (conversation) {
      const id =
        conversationContext.channelId === undefined
          ? conversationContext.userId
          : conversationContext.channelId
      update(`discussions/${facilityId}/${uid}/${id}`, { unreadCount: 0 })
    }
  }, [facilityId, uid, conversationContext, conversation])

  useEffect(() => {
    if (!hasUpdatedLastRead.current && conversation) {
      if (lastReadTS !== undefined) {
        const firstNewBubbleEntry = Object.entries(conversation ?? {}).find(
          ([_key, bubble]) => bubble.timestamp > lastReadTS,
        )
        if (firstNewBubbleEntry !== undefined)
          setFirstNewBubbleKey(firstNewBubbleEntry[0])
      }

      if (updateLastReadTS) updateLastReadTS(Date.now())
      hasUpdatedLastRead.current = true
    }
  }, [conversation, lastReadTS, updateLastReadTS])

  if (loading)
    return (
      <Centered>
        <Trans>Chargement...</Trans>
      </Centered>
    )
  if (error)
    return (
      <Centered>
        <Trans>Erreur</Trans>
      </Centered>
    )

  return (
    <div className="flex h-full flex-1 flex-col">
      <div className="flex-1 overflow-y-auto" ref={listRef}>
        {conversation ? (
          Object.entries(conversation).map(([key, bubble]) => {
            const senderId = getSenderId(user, conversationId)

            const isUserBubble = bubble.userId === senderId

            const author =
              bubble.userId === ADMIN_USER_ID
                ? conversationId === techChannelId
                  ? t(translateChannelNames[techChannelId])
                  : t(translateChannelNames[osoChannelId])
                : // if contact is not define, no author is displayed
                  contacts[bubble.userId]?.name

            return (
              <React.Fragment key={key}>
                {key === firstNewBubbleKey && (
                  <div className="border-t-background-notification h-1 w-full border-t-2 border-dotted py-2" />
                )}
                <div
                  className={cn(
                    'mb-3 flex flex-row',
                    isUserBubble ? 'justify-end' : 'justify-start',
                  )}
                >
                  <Bubble
                    bubble={bubble}
                    zone={contacts[bubble.userId]?.zone}
                    author={author}
                    isUserBubble={isUserBubble}
                  />
                </div>
              </React.Fragment>
            )
          })
        ) : (
          <div className="flex h-full">
            <Centered>
              <Trans>Pas de conversation</Trans>
            </Centered>
          </div>
        )}
      </div>

      <Composer
        conversationId={conversationId}
        conversationContext={conversationContext}
        facilityId={facilityId}
      />
    </div>
  )
}
