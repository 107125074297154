import {
  Alert,
  alertTypeString,
  BEEP_TYPE_MAPPING,
  isBeepAlert,
  isVocalAlert,
} from 'shared/apps/live/alert'
import { isDefined } from 'shared/utils/defined'
import { ms } from 'shared/utils/time'

export const ALERT_NOTIFICATION_TTL = ms(1, 'hour')

// 🌪️ i18n warning : update accordingly in live/src/common/alert.i18n.ts 🌪️
export function alertDisplayText(alert: Alert) {
  if (isBeepAlert(alert)) {
    const text = isDefined(alert.beepType)
      ? [...new Set(alert.beepType.map((txt) => BEEP_TYPE_MAPPING[txt]))].join(
          ', ',
        )
      : ''
    return `Bip ${text}`
  }

  if (isVocalAlert(alert)) {
    const filteredKeywords = alert.rawKeywords.filter(
      (keyword) => keyword.length > 0,
    )
    if (filteredKeywords.length > 0) {
      return [
        ...new Set(filteredKeywords.map((keyword) => `"${keyword}"`)),
      ].join(', ')
    }
  }
  return alertTypeString[alert.type]
}
