import { msg, t } from '@lingui/core/macro'
import { Trans, useLingui } from '@lingui/react/macro'
import { DialogDescription } from '@radix-ui/react-dialog'
import {
  ConversationPath,
  techChannelId,
  TECHNICIAN_USER_ID,
} from 'common/channels'
import { Power, Trash, TriangleAlert } from 'lucide-react'
import { useContext, useState } from 'react'
import { ActionType } from 'shared/apps/live/databaseSchema'
import { Bubble } from 'shared/apps/live/types'
import { Room, Serial } from 'shared/types/utils'
import { toast } from 'sonner'
import { AudioTextInput } from '../../../components/AudioTextInput'
import { CameraInput } from '../../../components/CameraInput'
import { FormError } from '../../../components/conversation/FormError'
import { Button } from '../../../components/ui/Button'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../../components/ui/Card'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../components/ui/Dialog'
import { useASUser } from '../../../components/UserProvider'
import { push } from '../../../firebaseMethods'
import { useMessagePoster } from '../../../hooks/useMessagePoster'
import { contactsContext } from '../../Live/contactsProvider'
import { saveFacilityChannelBubble } from '../../Live/GroupComposer'

const CHOICES = {
  OFFLINE: 'OFFLINE',
  ROOT: 'ROOT',
  OTHERS: 'OTHERS',
} as const

type ChoiceType = (typeof CHOICES)[keyof typeof CHOICES]

const CHOICES_TITLE = {
  OFFLINE: msg`Le boitier est hors ligne`,
  ROOT: msg`Gérer un problème`,
  OTHERS: msg`Signaler un problème`,
}

export const ReportBugDialog = ({
  serial,
  room,
  id,
}: {
  serial: Serial
  room: Room
  id: string
}) => {
  const { facilityId } = useASUser()
  const { t } = useLingui()
  const [open, setOpen] = useState<boolean>(false)
  const [choice, setChoice] = useState<ChoiceType>(CHOICES.ROOT)

  const handleClose = () => {
    setOpen(false)
    setChoice(CHOICES.ROOT)
  }
  const handleBack = () => setChoice(CHOICES.ROOT)
  const handleSubmit = async (type: ActionType) => {
    await push(`actions/${facilityId}/tech`, {
      type,
      createdAt: Date.now(),
      userId: TECHNICIAN_USER_ID,
      serial,
      room,
      id: id ?? 'id',
    })

    return handleClose()
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive" size="full" className="h-12">
          <TriangleAlert className="mr-2 size-4" />
          <Trans>Gérer un problème</Trans>
        </Button>
      </DialogTrigger>
      <DialogContent className="w-[90dvw]">
        <DialogHeader>
          <DialogTitle>{t(CHOICES_TITLE[choice])}</DialogTitle>
          <DialogDescription className="hidden" />
        </DialogHeader>
        {choice === CHOICES.ROOT && (
          <ChoicesContent
            handleClose={handleClose}
            handleChoiceClick={setChoice}
          />
        )}
        {choice === CHOICES.OFFLINE && (
          <ReportOfflineContent
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        )}
        {choice === CHOICES.OTHERS && (
          <OthersContent
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleBack={handleBack}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

type ChoicesContentProps = {
  handleClose: () => void
  handleChoiceClick: (choice: ChoiceType) => void
}

const ChoicesContent = ({
  handleChoiceClick,
  handleClose,
}: ChoicesContentProps) => {
  return (
    <>
      <Button
        variant="secondary"
        size="full"
        onClick={() => handleChoiceClick(CHOICES.OFFLINE)}
      >
        <Trans>Le boitier est hors ligne</Trans>
      </Button>
      <Button
        variant="secondary"
        size="full"
        onClick={() => handleChoiceClick(CHOICES.OTHERS)}
      >
        <Trans>Signaler un problème</Trans>
      </Button>
      <DialogFooter>
        <Button variant="outline" size="full" onClick={handleClose}>
          <Trans>Fermer</Trans>
        </Button>
      </DialogFooter>
    </>
  )
}

type ReportOfflineContentProps = {
  handleClose: () => void
  handleSubmit: (type: ActionType) => void
}

const ElecWarningCard = () => (
  <Card className="border bg-inherit text-sm text-black">
    <CardHeader className="p-2">
      <CardTitle className="flex flex-row items-center">
        <TriangleAlert className="mr-1 size-10 text-yellow-400" />
        <Trans>
          Soyez vigilant, respectez les consignes de sécurité électrique
        </Trans>
      </CardTitle>
    </CardHeader>
  </Card>
)

const ReportOfflineContent = ({
  handleClose,
  handleSubmit,
}: ReportOfflineContentProps) => {
  const [stepIndex, setStepIndex] = useState<number>(0)

  const handlePlugin = async () => {
    handleClose()
    handleSubmit(ActionType.OFFLINE_DEVICE)

    toast.info(
      t`Nous allons surveiller le retour en ligne de ce boîtier et vous tiendrons informés via la messagerie`,
    )
  }
  return (
    <>
      {stepIndex === 0 && (
        <>
          <Trans>Le boitier est-il bien branché sur le secteur ?</Trans>
          <DialogFooter className="flex flex-row justify-between">
            <Button variant="ghost" onClick={handleClose}>
              <Trans>Fermer</Trans>
            </Button>
            <div className="flex gap-1">
              <Button onClick={() => setStepIndex(2)} variant="secondary">
                <Trans>Non</Trans>
              </Button>
              <Button onClick={() => setStepIndex(1)} variant="default">
                <Trans>Oui</Trans>
              </Button>
            </div>
          </DialogFooter>
        </>
      )}
      {stepIndex === 1 && (
        <>
          <ElecWarningCard />

          <Card className="border bg-inherit text-black">
            <CardHeader>
              <CardTitle className="text-md flex items-center gap-2">
                <Power className="size-4" />
                <Trans>Rebranchement de la prise</Trans>
              </CardTitle>
            </CardHeader>
            <CardContent className="text-sm">
              <ol className="list-inside list-decimal space-y-1">
                <li>
                  <Trans>Débranchez la prise de courant</Trans>
                </li>
                <li>
                  <Trans>Attendez quelques secondes</Trans>
                </li>
                <li>
                  <Trans>Rebranchez la prise</Trans>
                </li>
              </ol>
            </CardContent>
          </Card>

          <DialogFooter className="flex flex-row justify-between">
            <Button variant="ghost" onClick={handleClose}>
              <Trans>Fermer</Trans>
            </Button>
            <div className="flex gap-1">
              <Button onClick={handlePlugin} variant="submit">
                <Trans>Prise rebranchée</Trans>
              </Button>
            </div>
          </DialogFooter>
        </>
      )}
      {/* NO STEP */}
      {stepIndex === 2 && (
        <>
          <ElecWarningCard />
          <Trans>Rebranchez la prise</Trans>
          <DialogFooter className="flex flex-row justify-between">
            <Button variant="ghost" onClick={handleClose}>
              <Trans>Fermer</Trans>
            </Button>
            <div className="flex gap-1">
              <Button onClick={handlePlugin} variant="submit">
                <Trans>Prise rebranchée</Trans>
              </Button>
            </div>
          </DialogFooter>
        </>
      )}
    </>
  )
}

type OthersContentProps = {
  handleClose: () => void
  handleBack: () => void
  handleSubmit: (type: ActionType) => void
}

const OthersContent = ({
  handleSubmit,
  handleClose,
  handleBack,
}: OthersContentProps) => {
  const { facilityId } = useASUser()
  const { contacts } = useContext(contactsContext)

  const saveBubble = async (bubble: Bubble): Promise<ConversationPath[]> => {
    handleClose()

    handleSubmit(ActionType.REPORT_DEVICE)

    return Promise.all([
      saveFacilityChannelBubble(techChannelId, contacts, facilityId, bubble),
    ])
  }

  const {
    sendMessage,
    text,
    setText,
    audioBlob,
    setAudioBlob,
    error,
    photo,
    setPhoto,
    clearPhoto,
  } = useMessagePoster(techChannelId, saveBubble)

  return (
    <form method="post" onSubmit={sendMessage} className="flex flex-col gap-2">
      <AudioTextInput
        text={text}
        onTextChanged={setText}
        audioBlob={audioBlob}
        onAudioRecorded={setAudioBlob}
        variant="textarea"
        placeholder={t`Décrivez le problème en détail ...`}
      />
      {photo ? (
        <div className="relative inline-block rounded-lg">
          <img
            src={URL.createObjectURL(photo)}
            alt={t`Photo téléversée`}
            className="max-h-64 rounded object-cover"
          />
          <Button
            variant="destructive"
            size="icon"
            className="absolute right-3 top-3"
            onClick={clearPhoto}
          >
            <Trash className="size-4" />
          </Button>
        </div>
      ) : (
        <CameraInput
          file={photo}
          onFileSelected={setPhoto}
          label={t`Ajouter une photo`}
        />
      )}
      <FormError error={error} />
      <DialogFooter className="flex flex-row justify-between">
        <Button variant="ghost" onClick={handleClose} type="button">
          <Trans>Fermer</Trans>
        </Button>
        <div className="flex gap-1">
          <Button onClick={handleBack} variant="secondary" type="button">
            <Trans>Précédent</Trans>
          </Button>
          <Button variant="destructive" type="submit">
            <Trans>Envoyer</Trans>
          </Button>
        </div>
      </DialogFooter>
    </form>
  )
}
