import { Settings } from 'luxon'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Facility } from 'shared/apps/live'
import { Centered } from 'shared/components/Centered'
import { FacilityId } from 'shared/types/utils'
import { collator } from 'shared/utils/web/collator'
import { useFirebase } from '../hooks/useFirebase'

type FacilitiesContext = {
  facilities: Record<FacilityId, Facility>
  facility: Facility
  facilityId: FacilityId
  setFacilityId: (id: FacilityId) => void
}

export const FacilitiesProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { data, loading, error } = useFirebase('facilities')

  const facilities = useMemo(() => {
    if (!data) return {}

    return Object.entries(data)
      .sort(([, { name: nameA }], [, { name: nameB }]) =>
        collator.compare(nameA, nameB),
      )
      .reduce<Record<FacilityId, Facility>>(
        (acc, [facilityId, facility]) => ({ ...acc, [facilityId]: facility }),
        {},
      )
  }, [data])

  return (
    <>
      {loading ? (
        <Centered>Chargement...</Centered>
      ) : error ? (
        <Centered>Erreur lors du chargement des données</Centered>
      ) : (
        <FacilitiesProvider_ facilities={facilities}>
          {children}
        </FacilitiesProvider_>
      )}
    </>
  )
}

const selectedFacilityIdStorageKey = 'selectedFacilityId'

export const facilitiesContext = React.createContext<FacilitiesContext>({
  facilities: {},
  facility: null as unknown as Facility,
  facilityId: '',
  setFacilityId: (_facilityId: FacilityId) => {
    // do nothing
  },
})

const FacilitiesProvider_: React.FC<
  React.PropsWithChildren<{ facilities: Record<FacilityId, Facility> }>
> = ({ facilities, children }) => {
  const [facilityId, setFacilityIdHelper] = useState<FacilityId>(
    Object.keys(facilities)[0],
  )

  const setFacilityId = useCallback(
    (facilityId: FacilityId) => {
      localStorage.setItem(selectedFacilityIdStorageKey, facilityId)
      setFacilityIdHelper(facilityId)
      // Set the default time zone for luxon, to simulate a user located in the facility's timezone
      Settings.defaultZone = facilities[facilityId].timeZone
    },
    [facilities],
  )

  useEffect(() => {
    const storedFacilityId = localStorage.getItem(selectedFacilityIdStorageKey)
    if (storedFacilityId && Object.keys(facilities).includes(storedFacilityId))
      setFacilityId(storedFacilityId)
    // make sure to call setFacilityId in both cases, to set default time zone
    else setFacilityId(Object.keys(facilities)[0])
  }, [facilities, setFacilityId])

  const contextValue = {
    facilities,
    facilityId,
    facility: facilities[facilityId],
    setFacilityId,
  }

  return (
    <facilitiesContext.Provider value={contextValue}>
      {children}
    </facilitiesContext.Provider>
  )
}
