import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Facility } from 'shared/apps/live'
import { FacilityId } from 'shared/types/utils'
import { facilitiesContext } from '../../contexts/FacilitiesProvider'

export type LastReadTS = number | undefined

export type FacilityLastReadTS = Record<FacilityId, LastReadTS>

interface LastReadContextType {
  lastReadTS: FacilityLastReadTS
  lastReadTechnicianTS: FacilityLastReadTS
  updateLastRead: (facilityId: FacilityId, timestamp: number) => void
  updateTechnicianLastRead: (facilityId: FacilityId, timestamp: number) => void
  markConversationsAsRead: (facilityIds: FacilityId[]) => void
}

const LastReadContext = createContext<LastReadContextType | null>(null)

const FACILITY_LAST_READ_PREFIX = 'lastReadTS_'
const TECHNICIAN_LAST_READ_PREFIX = 'lastReadTechnicianTS_'

const createLastReadManager = (prefix: string) => {
  const useLastReadState = (facilities: Record<string, Facility>) => {
    const [lastReadMap, setLastReadMap] = useState<FacilityLastReadTS>({})

    useEffect(() => {
      const initialLastRead = Object.keys(
        facilities,
      ).reduce<FacilityLastReadTS>((acc, facilityId) => {
        const lastRead = localStorage.getItem(`${prefix}${facilityId}`)
        acc[facilityId] = lastRead ? parseInt(lastRead, 10) : 0
        return acc
      }, {})
      setLastReadMap(initialLastRead)
    }, [facilities])

    const updateLastRead = useCallback(
      (facilityIds: FacilityId[], timestamp: number) => {
        facilityIds.forEach((facilityId) => {
          localStorage.setItem(`${prefix}${facilityId}`, timestamp.toString())
        })

        setLastReadMap((prev) => {
          const updatedMap = { ...prev }
          facilityIds.forEach((facilityId) => {
            updatedMap[facilityId] = timestamp
          })
          return updatedMap
        })
      },
      [],
    )

    return { lastReadMap, updateLastRead }
  }

  return useLastReadState
}

const useFacilityLastRead = createLastReadManager(FACILITY_LAST_READ_PREFIX)
const useTechnicianLastRead = createLastReadManager(TECHNICIAN_LAST_READ_PREFIX)

export const LastReadProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { facilities } = useContext(facilitiesContext)

  const facilityLastRead = useFacilityLastRead(facilities)
  const technicianLastRead = useTechnicianLastRead(facilities)

  const updateLastRead = useCallback(
    (facilityId: FacilityId, timestamp: number) =>
      facilityLastRead.updateLastRead([facilityId], timestamp),
    [facilityLastRead],
  )

  const updateTechnicianLastRead = useCallback(
    (facilityId: FacilityId, timestamp: number) =>
      technicianLastRead.updateLastRead([facilityId], timestamp),
    [technicianLastRead],
  )

  const markConversationsAsRead = useCallback(
    (selectedFacilityIds: FacilityId[]) => {
      const timestamp = Date.now()
      facilityLastRead.updateLastRead(selectedFacilityIds, timestamp)
      technicianLastRead.updateLastRead(selectedFacilityIds, timestamp)
    },
    [facilityLastRead, technicianLastRead],
  )

  return (
    <LastReadContext.Provider
      value={{
        lastReadTS: facilityLastRead.lastReadMap,
        lastReadTechnicianTS: technicianLastRead.lastReadMap,
        updateLastRead,
        updateTechnicianLastRead,
        markConversationsAsRead,
      }}
    >
      {children}
    </LastReadContext.Provider>
  )
}

export const useLastReadContext = () => {
  const context = useContext(LastReadContext)
  if (!context) {
    throw new Error('useLastRead must be used within a LastReadProvider')
  }
  return context
}
