import { FacilityDevice, FirebaseASUser } from '.'
import { TimeRange } from '../../types/timeRange'
import {
  FacilityId,
  FacilityName,
  FirebaseKey,
  Room,
  UserId,
} from '../../types/utils'
import { Alert, AlertComment, AlertType } from './alert'
import { ActionType } from './databaseSchema'

export interface Device extends FacilityDevice {
  facilityId: FacilityId
  facilityName: FacilityName
  monitoringTimeRange: TimeRange
}

interface SnoozeInterval {
  start: number
  end: number
}

export type SnoozeHistory = Partial<
  Record<AlertType, Record<FirebaseKey, SnoozeInterval>>
>

type FirebaseAdminUser = {
  email: string
  role: 'admin'
  phoneNumber?: string
  zoneId: undefined // Hacky for typed methods
}

export type FirebaseUser = FirebaseASUser | FirebaseAdminUser

export type User = { uid: string } & FirebaseUser

export type CallStatus =
  | 'queued'
  | 'initiated'
  | 'ringing'
  | 'in-progress'
  | 'completed'
  | 'busy'
  | 'failed'
  | 'no-answer'
  | 'canceled'

export type CallStatusHistory = {
  duration: string // although it represents a number
  status: CallStatus[]
}

export const automaticUid = 'automatic'

export type RawAlert = Omit<Alert, 'soundURL'> & {
  sound: string /* wav encoded sound */
}
export type AlertDetails = {
  email: string
  date: string // ISO 8601
  type: AlertType
  room: Room
}

type AlertQuote = AlertComment & { details: AlertDetails }

export type Quote = AlertQuote

interface BubbleBase {
  timestamp: number
  userId: UserId
  image?: { url: string; path: string }
  quote?: Quote
}

interface TextBubble extends BubbleBase {
  text: string
}

interface AudioBubble extends BubbleBase {
  sound: { url: string; path: string }
}

export type Bubble = TextBubble | AudioBubble | BubbleBase | NotificationBubble

export type BubbleUpdate = {
  image?: { url: string; path: string }
  sound?: { url: string; path: string }
}

export interface NotificationBubble extends BubbleBase {
  notification: { type: ActionType; id: string }
}

export function isNotificationBubble(
  bubble: Bubble,
): bubble is NotificationBubble {
  return (bubble as NotificationBubble).notification !== undefined
}

export function isTextBubble(bubble: Bubble): bubble is TextBubble {
  return (bubble as TextBubble).text !== undefined
}

export function isAudioBubble(bubble: Bubble): bubble is AudioBubble {
  return (bubble as AudioBubble).sound !== undefined
}

// A conversation is no more than a collection of bubbles
// It is referenced by two users in their respective 'discussions'
// or by several users for 'channel' conversations
export type ConversationId = FirebaseKey | ChannelId
// Might become undefined when old bubbles are purged
export type Conversation = Record<FirebaseKey, Bubble>
export type Conversations = Partial<Record<ConversationId, Conversation>>

interface Discussion {
  conversationId: ConversationId
  unreadCount: number
}

// A ChannelId is used as a key in the 'discussions' data structure
// It is also the shared, associated conversationId in that case
export type ChannelId = string

// Double entry: userA / userB / discussion and userB / userA / discussion
// will share the same conversationId. unreadCount is per user.
export type UserDiscussions = Partial<Record<UserId | ChannelId, Discussion>>
export type Discussions = Partial<Record<UserId, UserDiscussions>>

export type Emergencies = Record<FirebaseKey, Emergency>

export interface Emergency {
  source: string
  date: string // ISO date
  sound: string
  handledAt?: string // ISO date
  handledBy?: UserId
  endAt?: string // ISO date
}

export type VideoSource = 'menu' | 'popup'
