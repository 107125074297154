import { Facility, FacilityContacts, RoomNames } from '.'
import {
  DateString,
  FacilityId,
  FacilityName,
  FirebaseKey,
  Room,
  Serial,
  UserId,
} from '../../types/utils'
import { VictoriaConfig } from '../../types/victoria'
import { ConnectedAppConfiguration } from '../../utils/function/connectedApp'
import { Zones } from '../fleet'
import { AlertType, SerialAlerts } from './alert'
import { LiveHistory, RoomState } from './roomState'
import {
  CallStatusHistory,
  Conversations,
  Device,
  Discussions,
  Emergencies,
  FirebaseUser,
  RawAlert,
  SnoozeHistory,
  VideoSource,
} from './types'

type DateLiveHistory = Partial<Record<Serial, LiveHistory>>

export interface UserRelease {
  web: {
    version: string
    timeStamp: number
  }
  android: {
    environment: string
    timeStamp: number
    version: number
  }
}

type SerialSnoozeHistory = Record<Serial, SnoozeHistory>

type VideoPlay = {
  videoId: string
  timestamp: number
  progress: number
  source: VideoSource
}

export type VideoTrackerAction =
  | 'POPUP_CLOSED'
  | 'POPUP_DISPLAYED'
  | 'POPUP_EXPANDED'
  | 'MENU_DISPLAYED'
  | 'POPUP_RESCHEDULED'

type VideoSourceTracker = {
  action: VideoTrackerAction
  timestamp: number
  source: VideoSource
}

export enum ActionType {
  REPORT_DEVICE = 'REPORT_DEVICE',
  OFFLINE_DEVICE = 'OFFLINE_DEVICE',
  ONLINE_DEVICE = 'ONLINE_DEVICE',
}

type BaseAction = { type: ActionType; createdAt: number; userId: UserId }

interface ReportDeviceAction extends BaseAction {
  serial: Serial
  room: Room
  id: string
}

type TechAction = ReportDeviceAction

export type LastAlertTimes = Partial<Record<AlertType, number>>
export type LastKeywordTimes = Partial<Record<string, number>>

interface HoppenConfig {
  endpointURL: string
  outgoingToken: string
  incomingToken: string
}

export type FacilityRecentAlerts = Record<
  DateString,
  Record<Serial, Record<FirebaseKey, true>>
>

export type DatabaseSchema = {
  actions: Record<FacilityId, { tech: Record<FirebaseKey, TechAction> }>
  alerts: Record<DateString, SerialAlerts>
  comments: Record<Serial, string>
  commentedAlerts: Record<
    FacilityId,
    Record<DateString, Record<Serial, Record<FirebaseKey, true>>>
  >
  contacts: FacilityContacts
  conversations: Partial<Record<FacilityId, Conversations>>
  connections: {
    reporting: ConnectedAppConfiguration
    hoppen: Partial<Record<FacilityId, HoppenConfig>>
    slack: {
      alertCommentWebHookUrl: string
      supportChannelWebHookUrl: string
      techChannelWebHookURL: string
    }
    liveStaging: ConnectedAppConfiguration & { isCopyEnabled: boolean } // only available in prod
    'victoria-metrics-layer3': VictoriaConfig
  }
  cspReport: Record<FirebaseKey, unknown>
  devices: Record<Serial, Device>
  discussions: Partial<Record<FacilityId, Discussions>>
  emergency: Partial<Record<FacilityId, Emergencies>>
  emergencyHistory: Partial<Record<FacilityId, Emergencies>>
  evaluationComments: Record<FirebaseKey, string>
  externalUsers: Record<string, UserId>
  facilities: Record<FacilityId, Facility>
  janus: Record<Serial, FirebaseKey>
  lastAlerts: Partial<Record<Serial, LastAlertTimes>>
  lastAlertsKeywords: Partial<Record<Serial, LastKeywordTimes>>
  lastValidatedAlerts: Partial<Record<Serial, LastAlertTimes>>
  live: Partial<Record<DateString, DateLiveHistory>>
  phoneCalls: Partial<Record<string, CallStatusHistory>>
  rawAlerts: Record<DateString, Record<Serial, Record<FirebaseKey, RawAlert>>>
  recentAlerts: Partial<Record<FacilityName, FacilityRecentAlerts>>
  roomNames: Partial<Record<FacilityId, RoomNames>>
  roomStates: Partial<Record<Serial, RoomState>>
  snooze: Record<DateString, SerialSnoozeHistory>
  tokens: Partial<Record<FacilityId, Record<string, UserId>>>
  userReleases: Record<UserId, UserRelease>
  users: Partial<Record<UserId, FirebaseUser>>
  version: string
  versionHistory: Record<string, number>
  videos: Record<UserId, Record<FirebaseKey, VideoPlay>>
  videoAction: Record<UserId, Record<FirebaseKey, VideoSourceTracker>>
  zones: Partial<Record<FacilityId, Zones>>
}
