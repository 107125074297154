import React from 'react'
import { Contact as ContactType } from 'shared/apps/live'
import { Button } from '../../components/ui/Button'
import { useUser } from '../../components/UserProvider'
import MessageIcon from '../../icons/message.svg?react'
import PhoneIcon from '../../icons/phone.svg?react'

interface Base {
  unreadCount: number
  showConversation: () => void
  canMakePhoneCalls: boolean
}

type Channel = { name: string; contact?: never }
type Contact = { name?: never; contact: ContactType }

type Props = Base & (Channel | Contact)

export const Contact: React.FC<Props> = ({
  unreadCount,
  showConversation,
  name,
  contact,
  canMakePhoneCalls,
}) => {
  const { role } = useUser()

  const isAdmin = role === 'admin'
  const label = name ?? contact.zone ?? contact.name
  const subLabel = contact && contact.zone ? contact.name : undefined

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <div>{label}</div>
        {subLabel && <div className="text-base italic">{subLabel}</div>}
      </div>
      <div className="flex flex-row items-center gap-2">
        {contact && contact.phoneNumber && canMakePhoneCalls && (
          <Button
            size="icon"
            variant="secondary"
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onClick={() => callPhone(contact.phoneNumber!)}
            disabled={isAdmin}
          >
            <PhoneIcon fill="currentColor" className="h-6" />
          </Button>
        )}
        {(contact === undefined || contact.type === 'user') && (
          <Button
            size="icon"
            variant="secondary"
            onClick={() => showConversation()}
            disabled={isAdmin}
          >
            <MessageIcon fill="currentColor" className="h-6" />
            {unreadCount > 0 && (
              <div className="bg-background-notification -ml-3 mb-3 h-4 w-4 rounded-full"></div>
            )}
          </Button>
        )}
      </div>
    </div>
  )
}

function callPhone(phoneNumber: string) {
  if (window.Android?.onCallStarted) window.Android.onCallStarted(phoneNumber)
}
