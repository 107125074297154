import { t } from '@lingui/core/macro'
import { Trans } from '@lingui/react/macro'
import { Camera, LoaderCircle } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import { cn } from 'shared/utils/web/cn'
import { Button } from './ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from './ui/Dialog'

interface CameraInputProps {
  onFileSelected: (file: File) => void
  file: File | null
  label?: string
}

export const CameraInput: React.FC<CameraInputProps> = ({
  onFileSelected,
  file,
  label,
}) => {
  const cameraInputRef = useRef<HTMLInputElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      onFileSelected(e.target.files[0])
    }
  }

  const openCamera = async () => {
    setIsCameraOpen(true)
    setIsLoading(true)
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' },
      })
      if (videoRef.current) {
        videoRef.current.srcObject = stream
        videoRef.current.play()
      }
    } catch (error) {
      console.error('Unable to access the camera:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current
      const context = canvas.getContext('2d')
      const video = videoRef.current
      if (context) {
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        context.drawImage(video, 0, 0, canvas.width, canvas.height)
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' })
            onFileSelected(file)
            closeCamera()
          }
        }, 'image/jpeg')
      }
    }
  }

  const closeCamera = () => {
    setIsCameraOpen(false)
    if (videoRef.current?.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream
      stream.getTracks().forEach((track) => track.stop())
      videoRef.current.srcObject = null
    }
  }

  useEffect(() => {
    if (file === null && cameraInputRef.current) {
      cameraInputRef.current.value = ''
    }
  }, [file])

  return (
    <>
      <Button
        size={label ? 'default' : 'icon'}
        variant="secondary"
        type="button"
        onClick={openCamera}
      >
        <Camera className="size-4" />
        <span className={cn(label ? 'ml-2' : 'sr-only')}>
          {label ?? t`Prendre une photo`}
        </span>
      </Button>
      <input
        type="file"
        ref={cameraInputRef}
        onChange={handleFileChange}
        accept="image/*"
        capture="environment"
        className="hidden"
      />
      {isCameraOpen && (
        <Dialog open={isCameraOpen} onOpenChange={setIsCameraOpen}>
          <DialogContent className="bg-background-dialog">
            <DialogTitle className="hidden" />
            {isLoading && (
              <div className="flex w-full items-center justify-center p-4">
                <LoaderCircle className="size-16 animate-spin" />
              </div>
            )}
            <video ref={videoRef} className={cn(isLoading && 'hidden')} />

            <DialogDescription className="text-dialog text-md text-center">
              <Trans>Usage strictement professionnel</Trans>
            </DialogDescription>
            <canvas ref={canvasRef} className="hidden" />
            <DialogFooter className="flex flex-col gap-2">
              <Button variant="submit" onClick={captureImage} size="lg">
                <Camera className="mr-2 size-6" />
              </Button>
              <Button variant="outline" onClick={closeCamera} size="lg">
                <Trans>Fermer</Trans>
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
