import { Trans } from '@lingui/react/macro'
import { FacilityDevice } from 'shared/apps/live'
import { translatedTimeRangeString } from 'shared/utils/timeRange.i18n'
import { collator } from 'shared/utils/web/collator'
import { Deferred } from 'shared/utils/web/deferred'
import {
  Dialog,
  USER_BACK_BUTTON,
  USER_CLOSED_DIALOG,
} from '../../components/Dialog'
import { Button } from '../../components/ui/Button'
import Arrow from '../../icons/arrow.svg?react'
import RoomExitIcon from '../../icons/room-exit.svg?react'

export type DeviceWithRoomExit = Required<
  Pick<FacilityDevice, 'room' | 'roomExitTimeRange'>
>
export const RoomExitsDialog: React.FC<{
  deferred: Deferred<void>
  devicesWithRoomExit: DeviceWithRoomExit[]
}> = ({ deferred, devicesWithRoomExit }) => {
  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      <div
        className="flex cursor-pointer flex-row items-center space-x-2"
        onClick={() => deferred.reject(USER_BACK_BUTTON)}
      >
        <Arrow className="w-4" fill="currentColor" />
        <RoomExitIcon className="w-8" fill="currentColor" />
        <span className="flex-1">
          <Trans>
            Détection des "sorties de chambre" activée pour les chambres
            suivantes :
          </Trans>
        </span>
      </div>
      <div className="flex flex-col space-y-3 overflow-y-auto">
        {devicesWithRoomExit
          .sort(({ room: roomA }, { room: roomB }) =>
            collator.compare(roomA, roomB),
          )
          .map((device, index) => (
            <div key={index} className="flex gap-4 px-4">
              <div className="flex w-full justify-between">
                <div className="truncate font-bold">{device.room}</div>
                <div>{translatedTimeRangeString(device.roomExitTimeRange)}</div>
              </div>
            </div>
          ))}
      </div>
      <div className="flex flex-row">
        <Button
          variant="outline"
          size="full"
          onClick={() => deferred.reject(USER_CLOSED_DIALOG)}
        >
          <Trans>Fermer</Trans>
        </Button>
      </div>
    </Dialog>
  )
}
