import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/core/macro'
import { Trans, useLingui } from '@lingui/react/macro'
import { facilityChannelId, osoChannelId, techChannelId } from 'common/channels'
import React, { useContext, useMemo } from 'react'
import { ContactType } from 'shared/apps/live'
import { ChannelId } from 'shared/apps/live/types'
import { Button } from '../../components/ui/Button'
import { useUser } from '../../components/UserProvider'
import { ConversationContext } from './Communication'
import { Contact } from './Contact'
import { contactsContext } from './contactsProvider'

export const translateChannelNames: Record<ChannelId, MessageDescriptor> = {
  [osoChannelId]: msg`Service clients OSO`,
  [facilityChannelId]: msg`Messagerie interne`,
  [techChannelId]: msg`Service technique OSO`,
}

export const Contacts: React.FC<{
  setConversationContext: (_: ConversationContext) => void
  onClose: () => void
}> = ({ setConversationContext, onClose }) => {
  const { uid } = useUser()
  const { contacts, discussions } = useContext(contactsContext)
  const { t } = useLingui()
  // If the phone has a SIM card and can make phone calls, it should
  // have its own phone number set in fleet and exported to contacts
  const canMakePhoneCalls = contacts?.[uid]?.phoneNumber !== undefined

  const sortedContactsEntries = useMemo(
    () =>
      Object.entries(contacts ?? {})
        .filter(([userId, _]) => userId !== uid)
        .sort(([_userId1, { name: name1 }], [_userId2, { name: name2 }]) =>
          name1.localeCompare(name2),
        ),
    [uid, contacts],
  )

  const ContactsBlock: React.FC<{ title: string; type: ContactType }> = ({
    title,
    type,
  }) => {
    const contactEntries = sortedContactsEntries.filter(
      ([_, { type: type_ }]) => type_ === type,
    )

    if (contactEntries.length === 0) return null

    return (
      <div className="flex flex-col space-y-4">
        <h2 className="border-b border-current text-xl font-bold">{title}</h2>
        {contactEntries.map(([userId, contact]) => (
          <Contact
            key={userId}
            contact={contact}
            unreadCount={discussions?.[userId]?.unreadCount ?? 0}
            showConversation={() =>
              setConversationContext({ userId, channelId: undefined })
            }
            canMakePhoneCalls={canMakePhoneCalls}
          />
        ))}
      </div>
    )
  }

  const Channels: React.FC<{ title: string }> = ({ title }) => (
    <div className="flex flex-col space-y-4">
      <h2 className="border-b border-current text-xl font-bold">{title}</h2>
      {Object.entries(translateChannelNames)
        // remove tech channel for AS user
        .filter(([channelId]) => channelId !== techChannelId)
        .map(([channelId, channelName]) => {
          const trChannelName = t(channelName)
          return (
            <Contact
              key={channelId}
              name={trChannelName}
              unreadCount={discussions?.[channelId]?.unreadCount ?? 0}
              showConversation={() =>
                setConversationContext({
                  channelId,
                  channelName: trChannelName,
                })
              }
              canMakePhoneCalls={canMakePhoneCalls}
            />
          )
        })}
    </div>
  )

  return (
    <>
      <div className="flex-1 overflow-auto">
        <div className="flex flex-col space-y-6">
          <Channels title={t`Groupes`} />
          <ContactsBlock title={t`Contacts`} type="user" />
          {canMakePhoneCalls && (
            <ContactsBlock title={t`Externes`} type="external" />
          )}
        </div>
      </div>
      <div className="flex flex-row">
        <Button size="full" variant="secondary" onClick={onClose}>
          <Trans>Fermer</Trans>
        </Button>
      </div>
    </>
  )
}
