import { osoChannelId, techChannelId } from 'common/channels'
import { PanelRight } from 'lucide-react'
import { useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Conversation as ConversationType } from 'shared/apps/live/types'
import { Centered } from 'shared/components/Centered'
import { FacilityId } from 'shared/types/utils'
import { cn } from 'shared/utils/web/cn'
import { Button } from '../../../components/ui/Button'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '../../../components/ui/Tabs'
import { facilitiesContext } from '../../../contexts/FacilitiesProvider'
import { contactsContext, ContactsProvider } from '../../Live/contactsProvider'
import { Conversation } from '../../Live/Conversation'
import { useConversationsContext } from '../ConversationsContext'
import { LastReadTS, useLastReadContext } from '../LastReadContext'
import { UnreadCount } from '../UnreadCount'
import { AlertsList, Panel } from './AlertsPanel'

export const BOTTOM_BORDER_CLASSNAME = 'border border-border/50 shadow-2xl'

export const FacilityPanelLayout = () => {
  const { facilityId } = useParams<{ facilityId: string }>()
  const { facilities } = useContext(facilitiesContext)
  const { techncianConversations, facilityConversations } =
    useConversationsContext()

  const {
    lastReadTS,
    lastReadTechnicianTS,
    updateLastRead,
    updateTechnicianLastRead,
  } = useLastReadContext()

  const [showPanel, setShowPanel] = useState<boolean>(false)

  if (!facilityId) {
    return null
  }

  const facility = facilities[facilityId]

  if (!facility) {
    return (
      <Centered>Erreur : L'établissement demandé est introuvable.</Centered>
    )
  }

  return (
    <div className="text-foreground flex h-full grow flex-col">
      <div
        className={cn(
          'bg-header text-header-foreground flex flex-row justify-between p-2 text-2xl font-bold',
          BOTTOM_BORDER_CLASSNAME,
        )}
      >
        {facility.name}
        <Button
          size="icon"
          variant="secondary"
          className="bg-background text-primary hover:bg-background/60 gap-2 shadow"
          onClick={() => setShowPanel(!showPanel)}
          title="Afficher le panneau des alertes"
        >
          <PanelRight />
        </Button>
      </div>
      <ContactsProvider facilityId={facilityId}>
        <div className="flex h-[calc(100vh-100px)] flex-row justify-center">
          <ConversationLoader
            facilityId={facilityId}
            facilityName={facility.name}
            lastReadTS={lastReadTS[facilityId] ?? 0}
            lastReadTechnicianTS={lastReadTechnicianTS[facilityId] ?? 0}
            onUpdateLastRead={updateLastRead}
            onUpdateTechnicianLastReadTS={updateTechnicianLastRead}
            conversation={facilityConversations[facilityId]}
            technicianConversation={techncianConversations[facilityId]}
          />
          {showPanel && (
            <Panel title="Commentaires d'alertes sur les 7 derniers jours">
              <AlertsList facilityId={facilityId} />
            </Panel>
          )}
        </div>
      </ContactsProvider>
    </div>
  )
}

interface ConversationLoaderProps {
  facilityId: FacilityId
  facilityName: string
  lastReadTS: LastReadTS
  lastReadTechnicianTS: LastReadTS
  onUpdateLastRead: (facilityId: FacilityId, timestamp: number) => void
  onUpdateTechnicianLastReadTS: (
    facilityId: FacilityId,
    timestamp: number,
  ) => void
  conversation: ConversationType | undefined
  technicianConversation: ConversationType | undefined
}

const ConversationLoader: React.FC<ConversationLoaderProps> = ({
  facilityId,
  facilityName,
  lastReadTS,
  lastReadTechnicianTS,
  onUpdateLastRead,
  onUpdateTechnicianLastReadTS,
  conversation,
  technicianConversation,
}) => {
  const { loading, error } = useContext(contactsContext)

  const tabConfig = useMemo(
    () => [
      {
        label: 'AS',
        conversationId: osoChannelId,
        lastReadTS,
        updateLastRead: onUpdateLastRead,
        conversation,
      },
      {
        label: 'Technicien',
        conversationId: techChannelId,
        lastReadTS: lastReadTechnicianTS,
        updateLastRead: onUpdateTechnicianLastReadTS,
        conversation: technicianConversation,
      },
    ],
    [
      conversation,
      technicianConversation,
      lastReadTS,
      lastReadTechnicianTS,
      onUpdateLastRead,
      onUpdateTechnicianLastReadTS,
    ],
  )
  if (loading) return <Centered>Chargement...</Centered>
  if (error) return <Centered>Erreur</Centered>

  return (
    <div className="w-full">
      <Tabs defaultValue={tabConfig[0].label}>
        <TabsList className="w-full">
          {tabConfig.map(({ label, conversation, conversationId }, index) => (
            <TabsTrigger key={index} value={label} className="grow gap-2">
              {label}
              {conversation && (
                <UnreadCount
                  conversation={conversation}
                  lastReadTS={tabConfig[index].lastReadTS}
                  conversationId={conversationId}
                />
              )}
            </TabsTrigger>
          ))}
        </TabsList>
        {tabConfig.map(
          ({ label, conversationId, lastReadTS, updateLastRead }, index) => (
            <TabsContent key={index} value={label}>
              <div className="flex h-[calc(100vh-150px)] flex-col">
                <Conversation
                  facilityId={facilityId}
                  conversationId={conversationId}
                  conversationContext={{
                    channelId: conversationId,
                    channelName: facilityName,
                  }}
                  lastReadTS={lastReadTS}
                  updateLastReadTS={(ts) => updateLastRead(facilityId, ts)}
                />
              </div>
            </TabsContent>
          ),
        )}
      </Tabs>
    </div>
  )
}
