import { t } from '@lingui/core/macro'
import { Trans } from '@lingui/react/macro'
import { ConversationPath, SenderId } from 'common/channels'
import { Send, Trash } from 'lucide-react'
import React from 'react'
import { Bubble } from 'shared/apps/live/types'
import { useMessagePoster } from '../../hooks/useMessagePoster'
import { AudioTextInput } from '../AudioTextInput'
import { CameraInput } from '../CameraInput'
import { FileInput } from '../FileInput'
import { Button } from '../ui/Button'
import { FormError } from './FormError'

interface Props {
  saveBubble: (bubble: Bubble) => Promise<ConversationPath[]>
  isFileUploadEnabled: boolean
  senderId: SenderId
}

export const MessageInputForm: React.FC<Props> = ({
  saveBubble,
  isFileUploadEnabled,
  senderId,
}) => {
  const {
    text,
    setText,
    audioBlob,
    setAudioBlob,
    setPhoto,
    photo,
    error,
    sendMessage,
    clearPhoto,
  } = useMessagePoster(senderId, saveBubble)

  return (
    <div className="relative">
      <form
        method="post"
        onSubmit={sendMessage}
        className="flex flex-col rounded-lg bg-black bg-opacity-5 p-2"
      >
        <FormError error={error} />
        <div className="flex items-end space-x-2">
          {isFileUploadEnabled ? (
            <FileInput onFileSelected={setPhoto} file={photo} />
          ) : (
            <CameraInput onFileSelected={setPhoto} file={photo} />
          )}
          <AudioTextInput
            text={text}
            onTextChanged={setText}
            audioBlob={audioBlob}
            onAudioRecorded={setAudioBlob}
            placeholder={t`Votre message ici ...`}
            variant="textarea"
          />
          <Button
            type="submit"
            variant="submit"
            size="icon"
            disabled={(!text && !audioBlob && !photo) || !!error}
            className="sm:w-auto sm:px-4 sm:py-2"
          >
            <Send className="size-4 sm:mr-2" />
            <span className="hidden sm:block">
              <Trans>Envoyer</Trans>
            </span>
          </Button>
        </div>
      </form>
      {photo && (
        <div className="absolute bottom-full left-0">
          <div className="relative inline-block rounded-lg p-2 shadow-lg">
            <img
              src={URL.createObjectURL(photo)}
              alt={t`Photo téléversée`}
              className="max-h-64 rounded object-cover"
            />
            <Button
              variant="destructive"
              size="icon"
              className="absolute right-3 top-3"
              onClick={clearPhoto}
            >
              <Trash className="size-4" />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
