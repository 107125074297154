import { t } from '@lingui/core/macro'
import {
  Alert,
  BEEP_TYPE_MAPPING,
  isBeepAlert,
  isVocalAlert,
} from 'shared/apps/live/alert'
import {
  ALERT_TYPE_TRANSLATE,
  BEEP_TYPE_TRANSLATE,
} from 'shared/types/alert.i18n'

// 🌪️ i18n warning : update accordingly in live/src/common/alert.ts 🌪️
export function translateAlertDisplayText(alert: Alert) {
  if (isBeepAlert(alert)) {
    const text = [
      ...new Set(
        alert.beepType.map((txt) =>
          t(BEEP_TYPE_TRANSLATE[BEEP_TYPE_MAPPING[txt]]),
        ),
      ),
    ].join(', ')
    return t`Bip ${text}`
  }

  if (isVocalAlert(alert)) {
    const filteredKeywords = alert.rawKeywords.filter(
      (keyword) => keyword.length > 0,
    )
    if (filteredKeywords.length > 0) {
      return [...new Set(filteredKeywords)]
        .map((keyword) => `"${keyword}"`)
        .join(', ')
    }
  }
  return t(ALERT_TYPE_TRANSLATE[alert.type])
}
